import { filter } from 'rxjs/operators';
import * as Events from '../../events';
import { UiEvent, UiEventDispatcher } from '../../services/ui-event-dispatcher/ui-event-dispatcher';

export class Background {

    private _window: any;
    private _bgEl: HTMLElement;
    private _bgInnerEl: HTMLElement;
    private _evDispatcher: UiEventDispatcher;

    constructor(_window: any, el: HTMLElement, _evDispatcher: UiEventDispatcher) {
        this._window = _window;
        this._bgEl = el;
        this._bgInnerEl = el.querySelector('.background-inner');
        this._evDispatcher = _evDispatcher;

        this._bgEl.style.height = window.screen.height + 'px';

        this._evDispatcher
            .$events
            .subscribe(ev => this._reducer(ev));
    }

    private _reducer(ev: UiEvent): void {
        switch(ev.type){
            case Events.Base.Scroll:
                if(this._window.scrollY > 100){
                    this._bgInnerEl.classList.add('background-inner--active');
                } else {
                    this._bgInnerEl.classList.remove('background-inner--active')
                }
                break;
            case Events.Base.Resize:
                this._bgEl.style.height = window.screen.height + 'px';
                break;
        }
    }
}
