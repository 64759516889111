import * as Events from '../../events';
import { UiEventDispatcher } from '../../services/ui-event-dispatcher/ui-event-dispatcher';
import { forEachEl } from '../../utils/for-each-el';

export class Header {

    private _window: any;
    private _headerEl: HTMLElement;
    private _evDispatcher: UiEventDispatcher;

    constructor(_window: any, el: HTMLElement, _evDispatcher: UiEventDispatcher) {
        this._window = _window;
        this._headerEl = el;
        this._evDispatcher = _evDispatcher;

        this._evDispatcher
            .$events
            .subscribe(ev =>
                this._reducer(ev)
            );

        forEachEl('.nav-item a', navItemEl => {
            navItemEl.addEventListener('click', ev => {
                ev.preventDefault();

                const selector = navItemEl.getAttribute('href');

                this._window.document
                    .querySelector(selector)
                    .scrollIntoView({
                        behavior: 'smooth',
                        block: 'center'
                    });
            })
        });
    }

    private _reducer(ev): void {
        switch(ev.type){
            case Events.Base.Scroll:
                this._scrollHandler(ev);
                break;
            case Events.ScrollTracker.Activated:
                const activeEl = this._window.document.querySelector('[data-track-id="'+ev.payload+'"]')
                if(!!activeEl.id){
                    forEachEl('.nav-item', navItemEl => navItemEl.classList.remove('nav-item--active'));
                    const navItem = this._window.document.querySelector('.nav-item a[href="#'+activeEl.id+'"]');

                    if(!!navItem){
                        navItem.parentNode.classList.add('nav-item--active');
                    }

                }
                break;
        }
    }

    private _scrollHandler(ev): void {
        if(this._window.scrollY > 100){
            this._headerEl.classList.add('header--active');
        } else {
            this._headerEl.classList.remove('header--active')
        }
    }
}
