import { onReady } from "./utils/on-ready";
import { forEachEl } from "./utils/for-each-el";
import { UiEventDispatcher } from './services/ui-event-dispatcher/ui-event-dispatcher';
import * as Events from './events';
import { ScrollTracker } from './services/scroll-tracker/scroll-tracker';
import { Viewport } from "./components/viewport/viewport";
import { Modal } from "./components/modal/modal";
import { Background } from './components/background/background';
import { Header } from './components/header/header';
import { Logo } from './components/logo/logo';
import { Card } from './components/card/card';


onReady(window)((ev: Event, w: any) => {
    const evDispatcher = new UiEventDispatcher(w);
    const scrollTracker = new ScrollTracker(w, evDispatcher);

    new Viewport(w, evDispatcher);
    new Modal(w, evDispatcher);
    new Header(w, w.document.querySelector('header'), evDispatcher);
    new Logo(w, w.document.querySelector('.logo'), w.document.querySelector('.logo-small'), evDispatcher);

    forEachEl('.background-container', bg => new Background(window, bg, evDispatcher));
    forEachEl('.card', card => new Card(card, evDispatcher));


    // evDispatcher.$events.subscribe(ev => console.log(ev));

    // Sending GA events
    forEachEl('a[href]', el => {
        el.addEventListener('click', ev => {
            w.track_event('click', 'link', (el.innerText || null), (el.href || null));
        });
    });

    // RSVP 1
    const urlParams = new URLSearchParams(w.location.search);

    if(urlParams.get('rsvp1') && urlParams.get('id')){
        w.track_event('passive', 'rsvp', urlParams.get('rsvp1'), urlParams.get('id'));
    }

    if(w.location.pathname.includes('group-photo')){
        w.location.href = 'https://aoifeandsean.com/group_photo_qsa.jpg';
    }

});
