const Update = "scrolltracker.update";
const Activated = "scrolltracker.activated";
const EnteredViewport = "scrolltracker.enteredviewport";
const ExitedViewport = "scrolltracker.exitedviewport";

export {
  Update,
  Activated,
  EnteredViewport,
  ExitedViewport
};
