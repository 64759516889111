import { timer } from 'rxjs';
import * as Events from '../../events';
import { UiEventDispatcher } from '../ui-event-dispatcher/ui-event-dispatcher';

export class ScrollTracker {

    /**
     * Id iterator for tracked items
     */
    public static ID: number = 0;

    /**
     * Global Window Ref
     */
    private _window: any;

    /**
     * Ui Event Dispatcher
     */
    private _evDispatcher: UiEventDispatcher;

    /**
     *  Intersection Observer used to track elements entering and exiting the viewport
     */
    private _observer: IntersectionObserver;

    /**
     * Elements being observed (e.g. in the viewport)
     */
    private _observing: HTMLElement[];

    constructor(_window: any, _evDispatcher: UiEventDispatcher) {
        this._window = _window;
        this._evDispatcher = _evDispatcher;

        this._observer = new this._window.IntersectionObserver(
          (
            entries: IntersectionObserverEntry[],
            obs: IntersectionObserver
          ) => this._observationChange(entries, obs),
          this.observerSettings
        );

        this._observing = [];

        this._loop();
        this._initTracker();
    }

    private get observerSettings(): { rootMargin: string } {
      return { rootMargin: "0px 0px 0px 0px" };
    }

    /**
     * Gets the next ID iteration
     */
    private get nextId(): string {
      const id: string = ScrollTracker.ID.toString();
      ScrollTracker.ID++
      return id;
    }

    /**
     * Loop function to handle changes and emit updates
     */
    private _loop(): void {
      if(this._observing.length > 0){

        // Update each element & and build array with distances to compare
        const distances = this._observing
          .map(el => ({
            el: el,
            dist: this._updateElement(el)
          }));

        // Order array by distances
        distances.sort((a, b) => a.dist - b.dist);

        if(distances.length > 0){

          this._evDispatcher.$events.next({
            type: Events.ScrollTracker.Activated,
            payload: distances[0].el.dataset.trackId
          });

        }
      }

      timer(1000).subscribe(ev => this._loop());
    }

    /**
     * Initialises scroll tracker
     */
    private _initTracker(): void {
      // this._evDispatcher.$events
      //       .pipe(filter(ev => ev.type === Events.Base.))
      //       .subscribe(ev => this._activateTracking());

      this._activateTracking();
    }

    /**
     * Activates tracking for all elements on the page with data-track attribute
     */
    private _activateTracking(): void {

        // Activate click actions
        const els: HTMLElement[] = [... this._window.document.querySelectorAll('*[data-track]')];
        const elsToTrack: HTMLElement[] = els.filter(el => !el.dataset.tracked);

        elsToTrack.forEach(el => {
          el.dataset.trackId = this.nextId;
          this._observer.observe(el)
        });
    }

    /**
     * Observed state of a HTMLElement has changed.
     * @param entries IntersectionObserverEntry[]
     * @param obs IntersectionObserver
     */
    private _observationChange(entries: IntersectionObserverEntry[], obs: IntersectionObserver): void {
      entries.forEach(entry => {
        const el: HTMLElement = entry.target as HTMLElement;
        const trackId: string | undefined  = el.dataset.trackId;

        // Add/Remove element from array used to track items being observed.
        if(entry.isIntersecting && !this._observing.includes(el)){
          this._observing.push(el);

          this._evDispatcher.$events.next({
            type: Events.ScrollTracker.EnteredViewport,
            payload: trackId
          });
        } else if(!entry.isIntersecting && this._observing.includes(el)){
          this._observing.splice(this._observing.indexOf(el), 1);

          this._evDispatcher.$events.next({
            type: Events.ScrollTracker.ExitedViewport,
            payload: trackId
          });
        }
      });
    }

    /**
     * Updates element with latest position
     * @param el HTMLElement
     * @returns dist
     */
    private _updateElement(el: HTMLElement): number {

      const dist: number = this._distanceFromViewportVerticalCenter(el);
      const centered: number = 1 - ((dist / (this._window.innerHeight + el.clientHeight)) * 2);
      const trackId: string | undefined = el.dataset.trackId;

      this._evDispatcher.$events.next({
        type: Events.ScrollTracker.Update,
        payload: { id: trackId, centered }
      });

      return dist;
    }

    /**
     * Finds the distance of the element from the viewport's vertical center
     * @param el HTMLElement
     * @returns distance in pixels
     */
    private _distanceFromViewportVerticalCenter(el: HTMLElement): number {
      const rect = el.getBoundingClientRect();
      const viewportCenter: number = this._window.innerHeight/2;
      // From the elements center point (height/2)
      const elementPos: number = rect.top + (el.clientHeight/2);

      return Math.sqrt(Math.pow(viewportCenter - elementPos, 2));
    }

}
