import { UiEventDispatcher, UiEvent } from '../../services/ui-event-dispatcher/ui-event-dispatcher';
import * as Events from '../../events';

export class Modal {

    /**
     * Global Window Ref
     */
    private _window: any;

    /**
     * Ui Event Dispatcher
     */
    private _evDispatcher: UiEventDispatcher;

    private _modalEl: HTMLElement;

    constructor(_window: any, _evDispatcher: UiEventDispatcher) {
        this._window = _window;
        this._evDispatcher = _evDispatcher;

        this._modalEl = this._window.document.querySelector('.modal');

        // If modal doesn't exist, stop
        if(!this._modalEl) return;

        this._evDispatcher.$events.subscribe(ev => this.reducer(ev));
    }

    public get content(): HTMLElement {
        return this._modalEl.querySelector('.modal-content') as HTMLElement;
    }

    private _addContent(modalTmpId: number): void {
        const tpl = this._window.document.querySelector('#modal-' + modalTmpId);
        this.content.insertAdjacentHTML('beforeend', tpl.innerHTML);

        if(!!this._window['modal'+modalTmpId]){
            this._window['modal'+modalTmpId]();
        }
    }

    private reducer(ev: UiEvent): void {
        switch(ev.type){
            case Events.Base.Resize:
                this.close();
                break;
            case Events.Modal.Open:
                this._addContent(ev.payload);
                this._modalEl.classList.add('modal--active');
                this._evDispatcher.$events.next({ type: Events.Viewport.Open, payload: "" });
                break;
            case Events.Modal.Close:
                this.close();
                break;
        }
    }

    private close(): void {
        this._modalEl.classList.remove('modal--active');
        this._evDispatcher.$events.next({ type: Events.Viewport.Close, payload: "" });
        this.content.innerHTML = "";
    }
}
