import { filter } from 'rxjs/operators';
import * as Events from '../../events';
import { UiEventDispatcher } from '../../services/ui-event-dispatcher/ui-event-dispatcher';

export class Logo {

    private _window: any;
    private _logoEl: HTMLElement;
    private _logoSmallEl: HTMLElement;
    private _evDispatcher: UiEventDispatcher;

    constructor(_window: any, _logo: HTMLElement, _logoSmall: HTMLElement, _evDispatcher: UiEventDispatcher) {
        this._window = _window;
        this._logoEl = _logo;
        this._logoSmallEl = _logoSmall;
        this._evDispatcher = _evDispatcher;

        this._evDispatcher
            .$events
            .pipe(filter(ev => ev.type === Events.Base.Scroll))
            .subscribe(ev => this._update());
    }

    private _update(): void {

        if(this._window.scrollY > (this._logoEl.clientTop + this._logoEl.clientHeight)) {
            this._logoEl.classList.add('logo--hidden');
            this._logoSmallEl.classList.add('logo-small--active');
        } else {
            this._logoEl.classList.remove('logo--hidden');
            this._logoSmallEl.classList.remove('logo-small--active');
        }
    }
}
