import { Subject } from 'rxjs';
import * as Events from '../../events';

export interface UiEvent {
    type: string;
    payload: any;
}

export class UiEventDispatcher {

    private _window: any;
    private _$events: Subject<UiEvent>;

    constructor(windowRef: any) {
        this._$events = new Subject<UiEvent>();
        this._window = windowRef;

        this._watch();

        // Send scroll event
        this._window.addEventListener('scroll',
            (ev: Event) => this._$events.next({ type: Events.Base.Scroll, payload: ev })
        );

        // Send scroll event
        this._window.addEventListener('resize',
            (ev: Event) => this._$events.next({ type: Events.Base.Resize, payload: ev })
        );

        this._activateActions();
    }

    public get $events(): Subject<UiEvent> {
        return this._$events;
    }

    private _watch(): void {

        MutationObserver = this._window.MutationObserver || this._window.WebKitMutationObserver;

        var observer = new MutationObserver((mutations, observer) => {
            this._$events.next({ type: Events.Base.Update, payload: "" });
            this._activateActions();
        });

        // define what element should be observed by the observer
        // and what types of mutations trigger the callback
        observer.observe(this._window.document, {
            subtree: true,
            attributes: true
            //...
        });
    }

    private _activateActions(): void {

        // Activate click actions
        const els: HTMLElement[] = [... this._window.document.querySelectorAll('*[data-action]')];

        els
        .filter(el => el.dataset.init !== '1')
        .forEach(el => {
            el.addEventListener('click',
                (ev: Event) => this.handleAction(ev)
            );
            el.dataset.init = '1';
        });
    }

    private handleAction(ev: Event): void {
        const el: HTMLElement | null = ev.currentTarget as HTMLElement;

        if(!!el){
            console.warn('[UI]: ' + el.dataset.action, el.dataset.payload);

            this._$events.next({
                type: el.dataset.action || '',
                payload: el.dataset.payload
            });

            if(!el.dataset.allowDefault){
                ev.preventDefault();
            }
        }
    }

}
