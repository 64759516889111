import * as ScrollTracker from "./services/scroll-tracker/scroll-tracker.events";

// import { Nav } from "./components/nav/nav.events";
import * as Viewport from "./components/viewport/viewport.events";
import * as Modal from "./components/modal/modal.events";
// import * as Carousel from "./components/carousel/carousel.events";
// import * as NewsArticles from "./components/news-articles/news-articles.events";
// import * as Toggle from "./components/toggle/toggle.events";

const Base = {
  Scroll: "scroll",
  Resize: "resize",
  Update: "update"
};

export {
  Base,

  ScrollTracker,

//   Nav,
  Viewport,
  Modal,
//   Carousel,
//   NewsArticles,
//   Toggle
};
