import { filter } from 'rxjs/operators';
import * as Events from '../../events';
import { UiEventDispatcher } from '../../services/ui-event-dispatcher/ui-event-dispatcher';

export class Card {

    private _cardEl: HTMLElement;
    private _evDispatcher: UiEventDispatcher;

    constructor(el: HTMLElement, _evDispatcher: UiEventDispatcher) {
        this._cardEl = el;
        this._evDispatcher = _evDispatcher;

        this._evDispatcher
            .$events
            .pipe(filter(ev => ev.type === Events.ScrollTracker.Update))
            .pipe(filter(ev => ev.payload.id === this._cardEl.dataset.trackId))
            .pipe(filter(ev => ev.payload.centered > 0))
            .subscribe(ev => this._cardEl.classList.add('card--active'));
    }
}
