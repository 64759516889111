
import * as Events from '../../events';
import { UiEventDispatcher, UiEvent } from '../../services/ui-event-dispatcher/ui-event-dispatcher';

export class Viewport {

    /**
     * Global Window Ref
     */
    private _window: any;

    /**
     * Ui Event Dispatcher
     */
    private _evDispatcher: UiEventDispatcher;

    private _viewportEl: HTMLElement;

    constructor(_window: any, _evDispatcher: UiEventDispatcher) {
        this._window = _window;
        this._evDispatcher = _evDispatcher;
        this._viewportEl = this._window.document.querySelector('.viewport');

        this._evDispatcher.$events.subscribe(ev => this.reducer(ev));
    }

    private reducer(ev: UiEvent): void {
        switch(ev.type){
            case Events.Base.Resize:
                this._viewportEl.classList.remove('viewport--active');
                break;
            case Events.Viewport.Open:
                this._viewportEl.classList.add('viewport--active');
                break;
            case Events.Viewport.Close:
                this._viewportEl.classList.remove('viewport--active');
                break;
        }
    }
}
